import React, { useState, useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import OrderSummary from './OrderSummary';
import { useCart } from './CartContext';
import { useAuth } from './AuthContext';
import { useBackend } from './BackendContext';
import axios from 'axios';
import ReactGA from 'react-ga4';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Checkout = () => {
  const [step, setStep] = useState('address');
  const [clientSecret, setClientSecret] = useState('');
  const [tax, setTax] = useState({ amount: 0, rate: 0 });
  const [shippingCost, setShippingCost] = useState(0);
  const [error, setError] = useState(null);

  const { state, dispatch } = useCart();
  const { accessToken } = useAuth();
  const backend = useBackend();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  const calculateSubtotal = () => {
    return state.cart.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  const calculateTotal = () => {
    const subtotal = parseFloat(calculateSubtotal());
    const taxAmount = parseFloat(tax.amount / 100) || 0;
    return (subtotal + taxAmount + shippingCost).toFixed(2);
  };

  const handleAddressSubmit = async (address) => {
    try {
      const response = await axios.post(
        `${backend}/api/payments/create-intent`,
        {
          currency: 'usd',
          shippingAddress: address,
          items: state.cart.map(item => ({
            product_id: item.id,
            quantity: item.quantity
          }))
        },
        { headers: { Authorization: `Bearer ${accessToken}` } }
      );
      console.log('PaymentIntent created:', response.data);
      console.log(response);
      setClientSecret(response.data.paymentIntent.client_secret);
      setTax({
        amount: parseFloat(response.data.taxCalculation.tax_amount_exclusive),
        rate: parseFloat(response.data.taxCalculation.tax_breakdown[0].tax_rate_details.percentage_decimal)
      });
      setShippingCost(parseFloat(response.data.shippingCost));
      setStep('payment');
    } catch (error) {
      console.error('Error creating PaymentIntent:', error);
      if (error.response && error.response.data.type === 'InsufficientStockError') {
        const { productName, availableStock, requestedQuantity } = error.response.data;
        setError(`Insufficient stock for ${productName}. Available: ${availableStock}, Requested: ${requestedQuantity}`);
        ReactGA.event({
          category: 'Create Payment Intent',
          action: 'exception',
          label: 'InsufficientStockError'
        });
      } else {
        setError('Failed to set up payment. Please try again.');
        ReactGA.event({
          category: 'Create Payment Intent',
          action: 'exception',
          label: error.message
        });
      }
    }
  };

  const createOrder = async (paymentIntentId) => {
    try {
      const response = await axios.post(`${backend}/api/orders`, { paymentIntentId }, {
        headers: { Authorization: `Bearer ${accessToken}` }
      });

      console.log('Order created successfully', response.data);
      dispatch({ type: 'CLEAR_CART' });
      return response.data.orderId;
    } catch (error) {
      console.error('Error creating order:', error);
      ReactGA.event({ category: 'Create Order', action: 'exception', label: error.response?.data?.error || 'Unknown error' });
      
      if (error.response?.data?.error === 'InsufficientStockError') {
        const { productId, requestedQuantity, availableStock } = error.response.data.details;
        const product = state.cart.find(item => item.id === productId);
        throw new Error(`Insufficient stock for ${product ? product.name : 'a product'}. Available: ${availableStock}, Requested: ${requestedQuantity}`);
      } else {
        throw new Error('Failed to create order. Please try again or contact support.');
      }
    }
  };

  return (
    <div className="container mt-5">
      <h2>Checkout</h2>
      <div className="row">
        <div className="col-md-6">
          <OrderSummary
            cart={state.cart}
            subtotal={calculateSubtotal()}
            tax={tax}
            shippingCost={shippingCost}
            total={calculateTotal()}
            showTax={step === 'payment'}
            showShipping={step === 'payment'}
          />
        </div>
        <div className="col-md-6">
          {error && <div className="alert alert-danger mt-3">{error}</div>}
          {step === 'address' && (
            <AddressForm onAddressSubmit={handleAddressSubmit} />
          )}
          {step === 'payment' && clientSecret && (
            <Elements stripe={stripePromise} options={{ clientSecret }}>
              <PaymentForm
                onOrderCreated={createOrder}
              />
            </Elements>
          )}
        </div>
      </div>
      <div className="mt-3 alert alert-info">
        <strong>Test Mode:</strong> Use Stripe test card number 4242 4242 4242 4242 with any future expiration date and any CVC.
      </div>
    </div>
  );
};

export default Checkout;
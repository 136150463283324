import React, { useEffect } from 'react';
import Settings from './Settings';
import Orders from './Orders';
import ProfileDetails from './ProfileDetails';
import { useAuth } from './AuthContext';
import { useNavigate, useLocation, Routes, Route, NavLink } from 'react-router-dom';

const ProfilePage = () => {
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login');
      return;
    }

    // Handle initial navigation based on location state
    if (location.state && location.state.activeTab) {
      navigate(`/profile/${location.state.activeTab}`);
    }
  }, [isAuthenticated, navigate, location.state]);

  return (
    <div className="container mt-4">
      <h2 className="mb-4">My Account</h2>
      <div className="row">
        <div className="col-md-3 mb-4">
          <div className="list-group">
            <NavLink
              to="/profile"
              end
              className={({ isActive }) => `Profile-Button list-group-item list-group-item-action ${isActive ? 'active' : ''}`}
            >
              Profile
            </NavLink>
            <NavLink
              to="/profile/orders"
              className={({ isActive }) => `Profile-Button list-group-item list-group-item-action ${isActive ? 'active' : ''}`}
            >
              Orders
            </NavLink>
            <NavLink
              to="/profile/settings"
              className={({ isActive }) => `Profile-Button list-group-item list-group-item-action ${isActive ? 'active' : ''}`}
            >
              Settings
            </NavLink>
          </div>
        </div>
        <div className="col-md-9">
          <div className="card">
            <div className="card-body">
              <Routes>
                <Route index element={<ProfileDetails />} />
                <Route path="orders" element={<Orders />} />
                <Route path="settings" element={<Settings />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
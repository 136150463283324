import React from 'react';

const TermsOfService = () => {
    return (
        <div className="container">
            <div className="col-12 col-md-10 offset-md-1">
                <h1 className="text-center mb-4">Terms of Service</h1>
                <p className="text-muted text-center mb-5"><em>Last updated: August 30, 2024</em></p>

                <div className="row">
                    <div className="col">
                        {sections.map((section, index) => (
                            <div key={index} className="mb-4">
                                <h2 className="h3 mb-3">{section.title}</h2>
                                {section.content}
                            </div>
                        ))}
                    </div>
                </div>

                <div className="mt-5 pt-3 border-top">
                    <p className=''>By using LED Reminder's website and services, you agree to these Terms of Service. </p>
                </div>
            </div>
        </div>
    );
};

const sections = [
    {
        title: "1. Acceptance of Terms",
        content: (
            <p className='text-left'>
                By accessing and using the LED Reminder website <a href='https://ledreminder.com' className="text-decoration-none">ledreminder.com</a> and services, you accept and agree to be bound by these Terms of Service. If you do not agree to these terms, please do not use our website or services.
            </p>
        )
    },
    {
        title: "2. Changes to Terms",
        content: (
            <p className='text-left'>
                We reserve the right to modify these terms at any time. We will always post the most current version on our website. By continuing to use the site after changes become effective, you agree to be bound by the revised terms.
            </p>
        )
    },
    {
        title: "3. Use of Services",
        content: (
            <>
                <p className='text-left'>You agree to use our services only for lawful purposes and in accordance with these Terms. You are prohibited from:</p>
                <div className="col-md-10 offset-md-1">
                    <ul className="">
                        <li className="">Using the services in any way that violates applicable laws or regulations</li>
                        <li className="">Attempting to interfere with or disrupt our services or servers</li>
                        <li className="">Impersonating LED Reminder or any other individual or entity</li>
                        <li className="">Using the services to transmit any malware or viruses</li>
                    </ul>
                </div>
            </>
        )
    },
    {
        title: "4. User Accounts",
        content: (
            <p className='text-left'>
                You are responsible for maintaining the confidentiality of your account and password. You agree to accept responsibility for all activities that occur under your account. If you believe your account security has been breached, please contact us immediately.
            </p>
        )
    },
    {
        title: "5. Product Information and Pricing",
        content: (
            <p className='text-left'>
                We strive to provide accurate product information and pricing. However, errors may occur. We reserve the right to correct any errors and to change or update information at any time without prior notice.
            </p>
        )
    },
    {
        title: "6. Orders and Payments",
        content: (
            <p className='text-left'>
                By placing an order, you are making an offer to purchase. We reserve the right to refuse or cancel any order for any reason. Payment for all orders must be made through the provided payment methods. All payments are processed securely through our third-party payment processor.
            </p>
        )
    },
    {
        title: "7. Shipping and Delivery",
        content: (
            <p className='text-left'>
                We will make every effort to ship products in a timely manner. However, shipping times are estimates and not guaranteed. We are not responsible for delays caused by shipping carriers or customs.
            </p>
        )
    },
    {
        title: "8. Returns and Refunds",
        content: (
            <p className='text-left'>
                Please refer to our separate Returns and Refunds Policy for information on returns, exchanges, and refunds.
            </p>
        )
    },
    {
        title: "9. Intellectual Property",
        content: (
            <p className='text-left'>
                All content on this website, including text, graphics, logos, and images, is the property of LED Reminder and protected by copyright laws. You may not use, reproduce, or distribute any content from this website without our express written permission.
            </p>
        )
    },
    {
        title: "10. Limitation of Liability",
        content: (
            <p className='text-left'>
                LED Reminder and its affiliates will not be liable for any indirect, incidental, special, consequential or punitive damages resulting from your use of our services or any products purchased through our website.
            </p>
        )
    },
    {
        title: "11. Disclaimer of Warranties",
        content: (
            <p className='text-left'>
                Our services and products are provided "as is" without any warranty, express or implied. We do not guarantee that our services will be uninterrupted, timely, secure, or error-free.
            </p>
        )
    },
    {
        title: "12. Product Usage",
        content: (
            <p className='text-left'>
                Our products are designed for personal use. We are not responsible for any damage or injury resulting from improper use, modification, or disassembly of our products. Users should follow all provided instructions and safety guidelines.
            </p>
        )
    },
    {
        title: "13. Customer Support",
        content: (
            <p className='text-left'>
                While we strive to provide excellent customer support, we do not guarantee resolution of all issues. We will make a reasonable effort to assist with product-related questions and concerns.
            </p>
        )
    },
    {
        title: "14. Governing Law",
        content: (
            <p className='text-left'>
                These Terms shall be governed by and construed in accordance with the laws of the state of Oregon, without regard to its conflict of law provisions.
            </p>
        )
    },
    {
        title: "15. Severability",
        content: (
            <p className='text-left'>
                If any provision of these Terms is found to be unenforceable or invalid, that provision will be limited or eliminated to the minimum extent necessary so that the Terms will otherwise remain in full force and effect and enforceable.
            </p>
        )
    }
];

export default TermsOfService;
import React, { useEffect } from 'react';
import { Routes, Route, NavLink, useNavigate, Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import AdminOrderPanel from './AdminOrderPanel';
import AdminProductPanel from './AdminProductPanel';
import ReactGA from 'react-ga4';

const AdminPanel = () => {
  const { isAuthenticated, isAdmin } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

    if (!isAuthenticated || !isAdmin) {
      navigate('/login');
    }
  }, [isAuthenticated, isAdmin, navigate]);

  return (
    <div className="container mt-4 text-center">
      <h2 className="mb-4">Admin Panel</h2>
      <div className="row">
        <div className="col-md-3 mb-4">
          <div className="list-group">
            <NavLink
              to="/admin/orders"
              className={({ isActive }) => `list-group-item list-group-item-action ${isActive ? 'active' : ''}`}
            >
              Orders
            </NavLink>
            <NavLink
              to="/admin/products"
              className={({ isActive }) => `list-group-item list-group-item-action ${isActive ? 'active' : ''}`}
            >
              Products
            </NavLink>
          </div>
        </div>
        <div className="col-md-9">
          <div className="card">
            <div className="card-body">
              <Routes>
                <Route index element={<Navigate to="/admin/orders" replace />} />
                <Route path="orders" element={<AdminOrderPanel />} />
                <Route path="products" element={<AdminProductPanel />} />
              </Routes>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdminPanel;
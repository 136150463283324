import './App.css';
import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import Register from './components/Register';
import Login from './components/Login';
import Cart from './components/Cart';
import Checkout from './components/Checkout';
import CheckoutSuccess from './components/CheckoutSuccess';
import Home from './components/Home';
import ProductPage from './components/ProductPage';
import Profile from './components/Profile';
import OrderDetail from './components/OrderDetail';
import { CartProvider } from './components/CartContext.js';
import { AuthProvider, useAuth } from './components/AuthContext.js';
import { BackendProvider } from './components/BackendContext.js';
import ConfirmEmail from './components/ConfirmEmail.js';
import ForgotPassword from './components/ForgotPassword.js';
import ConfirmForgotPassword from './components/ConfirmForgotPassword.js';
import Navbar from './components/NavBar.js';
import ReactGA from 'react-ga4';
import AdminPanel from './components/AdminPanel';
import AdminOrderDetail from './components/AdminOrderDetail';
import Footer from './components/Footer';
import PrivacyPolicy from './components/PrivacyPolicy.js';
import TermsOfService from './components/TermsOfService.js';
import Contact from './components/Contact.js';

// Load Stripe publishable key from environment variable
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

// ScrollToTop component
function ScrollToTop() {
  const { pathname } = useLocation();
  
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function AppContent() {
  const { isAuthenticated, isAdmin } = useAuth();

  const measurementId = process.env.REACT_APP_GOOGLE_ANALYTICS_MEASUREMENT_ID;
  ReactGA.initialize(measurementId);
  
  return (
    <div className="d-flex flex-column min-vh-100">
      <div className="flex-grow-1">
        <Navbar />
        <div className="main-content">
          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/register" element={<Register />} />
            <Route path="/login" element={<Login />} />
            <Route path="/cart" element={<Cart />} />
            <Route path="/products/:name" element={<ProductPage />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/confirm-email" element={<ConfirmEmail />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route path="/confirm-forgot-password" element={<ConfirmForgotPassword />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            {isAuthenticated && (
              <>
                <Route path="/profile/*" element={<Profile />} />
                <Route path="/order/:id" element={<OrderDetail />} />
                <Route path="/checkout" element={<Checkout />} />
                <Route path="/checkout/success" element={<CheckoutSuccess />} />
              </>
            )}
            {isAuthenticated && isAdmin && (
              <>
                <Route path="/admin/*" element={<AdminPanel />} />
                <Route path="/admin/order/:id" element={<AdminOrderDetail />} />
              </>
            )}
            <Route path="*" element={<Home/>} /> 
          </Routes>
        </div>
      </div>
      <Footer />
    </div>
  );
}

function App() {
  return (
    <GoogleReCaptchaProvider
      reCaptchaKey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
      scriptProps={{
        async: false,
        defer: false,
        appendTo: 'head',
        nonce: undefined,
      }}
    >
      <BackendProvider>
        <AuthProvider>
          <CartProvider>
            <Elements stripe={stripePromise}>
              <Router>
                <AppContent />
              </Router>
            </Elements>
          </CartProvider>
        </AuthProvider>
      </BackendProvider>
    </GoogleReCaptchaProvider>
  );
}

export default App;
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '../Form.css';
import { useAuth } from './AuthContext.js';
import { useNavigate, Link, Navigate } from 'react-router-dom';
import { useBackend } from './BackendContext.js';
import ReactGA from 'react-ga4';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';

function Login() {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [message, setMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const backend = useBackend();

  const { login, isAuthenticated } = useAuth();

  useEffect(() => {
    console.log('Page view logged');
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  useEffect(() => {
    let navigationTimer;

    if (message === 'Login successful') {
      console.log('Login successful, navigating to home');
      navigationTimer = setTimeout(() => {
        navigate('/');
      }, 1250);
    }

    return () => {
      if (navigationTimer) {
        clearTimeout(navigationTimer);
      }
    };
  }, [message, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log('Form submitted');
    setIsLoading(true);
    setMessage('');

    if (!executeRecaptcha) {
      console.log('Execute recaptcha not yet available');
      setIsLoading(false);
      return;
    }

    try {
      console.log('Executing recaptcha');
      const token = await executeRecaptcha('login');
      console.log('Recaptcha token:', token);
      const response = await axios.post(`${backend}/api/auth/login`, {
        username: email,
        password,
        recaptchaToken: token
      });
      console.log('Login response:', response);
      login(response.data);
      ReactGA.event({ category: 'Auth', action: 'login' });
      setMessage('Login successful');
    } catch (error) {
      console.log('Login error:', error);
      ReactGA.event({ category: 'Auth', action: 'exception', label: error.response?.status });
      if (error.response) {
        console.log('Error response:', error.response);
        setMessage(error.response.data);
      } else {
        setMessage('Error: ', error.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (isAuthenticated) {
    console.log('User is authenticated, redirecting to home');
    return <Navigate to="/" replace />;
  }

  return (
    <div className='container'>
      <div className='col'>
        <h2>Login</h2>
        <form onSubmit={handleSubmit}>
          <div className='row'>
            <label>Email:</label>
            <input className='loginInput' type="email" value={email} onChange={(e) => setEmail(e.target.value)} required />
          </div>
          <div className='row'>
            <label>Password:</label>
            <input className='loginInput' type="password" value={password} onChange={(e) => setPassword(e.target.value)} required />
          </div>
          <div className='row justify-content-center'>
            <button className='loginButton' type="submit" disabled={isLoading}>
              {isLoading ? 'Logging in...' : 'Login'}
            </button>
          </div>
        </form>
        <div className='row justify-content-center'>
          <p>{message}</p>
        </div>
        <div className='row justify-content-center'>
          <Link to='/Forgot-Password'>Forgot Password?</Link>
        </div>
      </div>
    </div>
  );
}

export default Login;
import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';
import { jwtDecode } from 'jwt-decode';
import axios from 'axios';
import { useBackend } from './BackendContext';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [accessToken, setAccessToken] = useState(null);
  const [refreshToken, setRefreshToken] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [loading, setLoading] = useState(true);
  const backend = useBackend();

  const logout = useCallback(() => {
    setIsAuthenticated(false);
    setAccessToken(null);
    setRefreshToken(null);
    setIsAdmin(false);
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
  }, []);

  const refreshTokens = useCallback(async () => {
    if (!refreshToken) {
      console.error('No refresh token available');
      logout();
      return;
    }

    try {
      console.log('Attempting to refresh token with:', refreshToken);
      const response = await axios.post(`${backend}/api/auth/refresh-token`, { refreshToken });
      console.log('Refresh token response:', response.data);

      const { accessToken: newAccessToken, refreshToken: newRefreshToken } = response.data;
      setAccessToken(newAccessToken);
      setRefreshToken(newRefreshToken);
      localStorage.setItem('accessToken', newAccessToken);
      localStorage.setItem('refreshToken', newRefreshToken);
      setIsAuthenticated(true);

      const decodedToken = jwtDecode(newAccessToken);
      const cognitoGroups = decodedToken['cognito:groups'] || [];
      setIsAdmin(cognitoGroups.includes('Admin'));

      // Set up the next refresh
      const expirationTime = decodedToken.exp * 1000;
      const currentTime = Date.now();
      const timeUntilExpiration = expirationTime - currentTime;
      if (timeUntilExpiration > 0) {
        setTimeout(refreshTokens, timeUntilExpiration - 60000);
      }
    } catch (error) {
      console.error('Error refreshing token:', error);
      logout();
    }
  }, [backend, refreshToken, logout]);

  const login = useCallback((tokens) => {
    const { accessToken, refreshToken } = tokens;
    setIsAuthenticated(true);
    setAccessToken(accessToken);
    setRefreshToken(refreshToken);
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);

    try {
      const decodedToken = jwtDecode(accessToken);
      const cognitoGroups = decodedToken['cognito:groups'] || [];
      setIsAdmin(cognitoGroups.includes('Admin'));
      
      const expirationTime = decodedToken.exp * 1000;
      const currentTime = Date.now();
      const timeUntilExpiration = expirationTime - currentTime;
      
      if (timeUntilExpiration > 0) {
        setTimeout(refreshTokens, timeUntilExpiration - 60000);
      } else {
        logout();
      }
    } catch (error) {
      console.error('Error decoding token:', error);
      setIsAdmin(false);
      logout();
    }
  }, [logout, refreshTokens]);

  useEffect(() => {
    const storedAccessToken = localStorage.getItem('accessToken');
    const storedRefreshToken = localStorage.getItem('refreshToken');
    if (storedAccessToken && storedRefreshToken) {
      login({ accessToken: storedAccessToken, refreshToken: storedRefreshToken });
    }
    setLoading(false);
  }, [login]);

  const value = {
    isAuthenticated,
    accessToken,
    isAdmin,
    login,
    logout,
    refreshTokens,
    loading
  };

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
};
import React, { useState, useEffect } from 'react';
import { useCart } from './CartContext';
import { useAuth } from './AuthContext';
import { useNavigate } from 'react-router-dom';
import '../Form.css';
import ReactGA from 'react-ga4';
import axios from 'axios';
import { useBackend } from './BackendContext';

const Cart = () => {
  const { state, dispatch } = useCart();
  const { isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const [showAuthMessage, setShowAuthMessage] = useState(false);
  const backend = useBackend();

  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
  }, []);

  useEffect(() => {
    const fetchLatestStock = async () => {
      try {
        const productIds = state.cart.map(item => item.id);
        const response = await axios.post(`${backend}/api/products/stock`, { productIds });
        const latestStock = response.data;

        latestStock.forEach(item => {
          dispatch({ type: 'UPDATE_STOCK', payload: { id: item.id, stock: item.stock } });
        });
      } catch (error) {
        console.error('Error fetching latest stock:', error);
      }
    };

    fetchLatestStock();
  }, [dispatch, state.cart, backend]);

  const handleRemove = (item) => {
    dispatch({ type: 'REMOVE_FROM_CART', payload: item });
    ReactGA.event({ category: 'Cart', action: 'remove_from_cart', label: item.id });
  };

  const handleQuantityChange = (item, quantity) => {
    if (quantity > item.stock) {
      quantity = item.stock;
    }
    dispatch({ type: 'UPDATE_QUANTITY', payload: { id: item.id, quantity } });
    ReactGA.event({ category: 'Cart', action: 'update_cart_quantity', label: item.id });
  };

  const handleCheckout = () => {
    if (isAuthenticated) {
      navigate('/checkout');
      ReactGA.event({ category: 'Cart', action: 'begin_checkout' });
    } else {
      setShowAuthMessage(true);
      setTimeout(() => setShowAuthMessage(false), 5000);
    }
  };

  const calculateTotal = () => {
    return state.cart.reduce((total, item) => total + item.price * item.quantity, 0).toFixed(2);
  };

  return (
    <div className="cart-container">
      <h2>Shopping Cart</h2>
      {state.cart.length === 0 ? (
        <p>Your cart is empty</p>
      ) : (
        <div className="list-group">
          {state.cart.map((item, index) => (
            <div key={index} className="list-group-item cart-item">
              <div className="row align-items-center text-center ">
                <div className="col-4 col-md-3 text-left pl-3 pl-lg-4">{item.name}</div>
                <div className="col-2 col-md-4 col-lg-4 text-sm-left text-right">${item.price}</div>
                <div className="col-2 col-md-2 text-right">
                  <input
                    type="number"
                    value={item.quantity}
                    onChange={(e) => handleQuantityChange(item, Number(e.target.value))}
                    min="1"
                    max={item.stock}
                    className="quantity-input form-control"
                  />
                </div>
                <div className="col-2 col-md-2 text-md-left">${(item.price * item.quantity).toFixed(2)}</div>
                <div className="col-2 col-md-1 text-right pl-0">
                  <button className="btn btn-danger btn-sm removeButton" onClick={() => handleRemove(item)}>X</button>
                </div>
              </div>
              {item.quantity === item.stock && (
                <div className="row">
                  <div className="col-12 text-danger">
                    Maximum available quantity reached
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {state.cart.length > 0 && (
        <div>
          <div className="total mt-3 text-center">
            <strong>Total: ${calculateTotal()}</strong>
          </div>
          <div className="d-flex justify-content-center">
            <button onClick={handleCheckout} className="btn btn-primary mt-3">Proceed to Checkout</button>
          </div>
          {showAuthMessage && (
            <div className="alert alert-warning mt-3 text-center" role="alert">
              You must be logged in to proceed to checkout. Please <a href="/login" className="alert-link">log in</a> or <a href="/register" className="alert-link">register</a>.
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Cart;
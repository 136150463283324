import React, { useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

const PaymentForm = ({ onOrderCreated }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [processing, setProcessing] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      console.error('Stripe.js has not loaded yet.');
      return;
    }

    setProcessing(true);

    ReactGA.event({ category: 'Checkout', action: 'submit_payment' });

    const { error: submitError } = await elements.submit();
    if (submitError) {
      setError(submitError.message);
      setProcessing(false);
      ReactGA.event({ category: 'Submit Payment', action: 'exception', label: submitError.code });
      return;
    }

    const result = await stripe.confirmPayment({
      elements,
      redirect: 'if_required',
    });

    if (result.error) {
      console.error('Payment confirmation error:', result.error);
      setError(result.error.message);
      setProcessing(false);
    } else if (result.paymentIntent && result.paymentIntent.status === 'succeeded') {
      console.log('Payment succeeded');
      try {
        const orderId = await onOrderCreated(result.paymentIntent.id);
        navigate(`/checkout/success?order_id=${orderId}`);
      } catch (error) {
        ReactGA.event({ category: 'Create Order', action: 'exception', label: error.message });
        console.error('Error creating order:', error);
        setError(error.message);
        setProcessing(false);
      }
    } else {
      console.log('Unexpected payment result:', result);
      setError('An unexpected error occurred. Please try again.');
      setProcessing(false);
      ReactGA.event({ category: 'Unexpected Payment Result', action: 'exception' });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <h3>Payment Information</h3>
      <PaymentElement />
      <button disabled={!stripe || processing} className="btn btn-primary mt-3">
        {processing ? 'Processing...' : 'Pay Now'}
      </button>
      {error && <div className="alert alert-danger mt-3">{error}</div>}
    </form>
  );
};

export default PaymentForm;